<template>
  <div class="d-flex flex-row">
    <div
      class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      id="kt_profile_aside"
    >
      <div class="card card-custom">
        <div class="card-body pt-15">
          <div class="text-center mb-10">
            <div class="symbol symbol-60 symbol-circle symbol-xl-90">
              <div
                class="symbol-label"
                :style="{
                  backgroundImage: `url(${avatar})`
                }"
              ></div>
              <i class="symbol-badge symbol-badge-bottom bg-success"></i>
            </div>

            <h4 class="font-weight-bold my-2">
              {{ fullname }}
            </h4>
            <span class="text-sm">Member Since </span>
            <time-ago
              class="text-sm"
              :refresh="60"
              :datetime="currentUser.created_at"
              locale="en"
              :long="true"
              tooltip
            ></time-ago>
            <div class="text-muted mb-2">
              {{ rolename }}
            </div>
            <span
              v-if="currentUser.active"
              class="label label-light-success label-inline font-weight-bold label-lg"
              >Active</span
            >
            <span
              v-if="!currentUser.active"
              class="label label-light-danger label-inline font-weight-bold label-lg"
              >Deactivated</span
            >
          </div>

          <!--begin::Nav-->
          <div
            class="navi navi-bold navi-hover navi-active navi-link-rounded"
            role="tablist"
          >
            <div class="navi-item mb-2">
              <a
                class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block active"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="0"
                data-toggle="tab"
                role="tab"
              >
                Personal Information
              </a>
              <a
                v-if="this.currentUser.company.action_party_type == 'group'"
                class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="1"
                data-toggle="tab"
                role="tab"
              >
                Action Parties
              </a>
              <a
                class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="2"
                data-toggle="tab"
                role="tab"
              >
                Change Password
              </a>
            </div>
          </div>
          <!--end::Nav-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Aside-->

    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <b-tabs class="hide-tabs" v-model="tabIndex">
        <b-tab active>
          <personal-information></personal-information>
        </b-tab>

        <b-tab v-if="this.currentUser.company.action_party_type == 'group'">
          <action-parties></action-parties>
        </b-tab>

        <b-tab>
          <change-password></change-password>
        </b-tab>
      </b-tabs>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PersonalInformation from "@/view/pages/user-profile/profile-comp/personal-information";
import ActionParties from "@/view/pages/user-profile/profile-comp/action-parties";
import ChangePassword from "@/view/pages/user-profile/profile-comp/change-password";
import { TimeAgo } from "vue2-timeago";

export default {
  name: "uesr-profile",
  data() {
    return {
      tabIndex: 0
    };
  },
  components: {
    PersonalInformation,
    ActionParties,
    ChangePassword,
    TimeAgo
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "User Profile" }]);
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll('[data-toggle="tab"]');
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      this.tabIndex = parseInt(target.getAttribute("data-tab"));
      target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters(["currentUser", "layoutConfig"]),
    fullname() {
      return this.currentUser.firstname + " " + this.currentUser.lastname;
    },
    rolename() {
      switch (this.currentUser.role_id) {
        case 1:
          return "Administrator";
        case 2:
          return "Editor";
        case 3:
          return "Contributor";
        default:
          return "Contributor";
      }
    },
    avatar() {
      return this.currentUser.avatar == null
        ? this.layoutConfig("avatar")
        : this.currentUser.avatar;
    }
  }
};
</script>
