import axios from "axios";

const UserService = {
  /**
   * Gets a user
   * @param  {} id
   */
  get(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`users/${id}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get all users
   * @param  {} slug
   */
  all(slug) {
    return new Promise((resolve, reject) => {
      axios
        .get(`users?${slug}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Update a single user by ID
   * @param id
   * @returns {*}
   */
  update(params, id) {
    return new Promise((resolve, reject) => {
      axios
        .put(`users/${id}`, params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Update a single user by ID
   * @param id
   * @returns {*}
   */
  updateState(activate, id) {
    const url =
      activate == true ? `users/activate/${id}` : `users/deactivate/${id}`;
    return new Promise((resolve, reject) => {
      axios
        .put(url)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Update a single user by ID
   * @param id
   * @returns {*}
   */
  updateParty(add, payload) {
    const url =
      add == true ? `/action-parties/user/add` : `/action-parties/user/remove`;
    return new Promise((resolve, reject) => {
      axios
        .post(url, payload)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * change password
   * @param credentials
   * @returns {*}
   */
  changePassword(params) {
    return new Promise((resolve, reject) => {
      axios
        .post("users/change-password", params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  }
};

export default UserService;
