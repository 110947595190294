<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Change Password</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Change your account password</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="submit"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" id="kt_password_change_form">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-alert text-right"
            >Current Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid mb-2t"
              :class="{ 'is-invalid': $v.current_password.$error }"
              type="password"
              autocomplete="false"
              v-model.trim="$v.current_password.$model"
            />
            <template v-if="$v.current_password.$error">
              <span
                class="form-text text-danger"
                v-if="!$v.current_password.required"
              >
                current password is required.
              </span>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-alert text-right"
            >New Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid mb-2t"
              :class="{ 'is-invalid': $v.password.$error }"
              type="password"
              autocomplete="false"
              v-model.trim="$v.password.$model"
            />
            <template v-if="$v.password.$error">
              <span class="form-text text-danger" v-if="!$v.password.required">
                password is required.
              </span>
              <span class="form-text text-danger" v-if="!$v.password.minLength">
                password should be atleast 8 characters.
              </span>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-alert text-right"
            >Verify Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid mb-2t"
              :class="{ 'is-invalid': $v.password_confirmation.$error }"
              type="password"
              autocomplete="false"
              v-model.trim="$v.password_confirmation.$model"
            />
            <template v-if="$v.password_confirmation.$error">
              <span
                class="form-text text-danger"
                v-if="!$v.password_confirmation.required"
              >
                please confirm password.
              </span>
              <span
                class="form-text text-danger"
                v-if="!$v.password_confirmation.sameAs"
              >
                passwords dont match.
              </span>
            </template>
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserService from "@/core/services/user.service";
import { required, sameAs, minLength } from "vuelidate/lib/validators";

export default {
  name: "ChangePassword",
  data() {
    return {
      current_password: "",
      password: "",
      password_confirmation: ""
    };
  },
  validations: {
    current_password: { required },
    password: { required },
    password_confirmation: {
      required,
      sameAs: sameAs("password"),
      minLength: minLength(8)
    }
  },
  methods: {
    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          current_password: this.current_password,
          password: this.password,
          password_confirmation: this.password_confirmation
        };

        const submitButton = this.$refs["kt_save_changes"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        UserService.changePassword(payload)
          .then(response => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            this.makeToast(
              "success",
              "Password Updated",
              response.data.message
            );
          })
          .catch(error => {
            if (error.status == 422) {
              let errors = Object.values(error.data.errors);
              errors = errors.flat();
              this.makeToast("danger", "Validation Error", errors[0]);
            } else if (error.status == 400) {
              this.makeToast("danger", error.statusText, error.data.message);
            } else {
              this.makeToast(
                "danger",
                "Oops",
                "An unexpected exception has occured. Please give it a minute and refresh page"
              );
            }
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            console.log(error);
          });
      }
    },
    cancel() {
      this.current_password = "";
      this.password = "";
      this.password_confirmation = "";
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  },
  computed: {
    ...mapGetters(["currentUser"])
  }
};
</script>
