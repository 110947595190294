<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Personal Information
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Update your personal informaiton</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
      </div>
    </div>
    <form class="form">
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6"></div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Avatar</label
          >
          <div class="col-lg-9 col-xl-6">
            <label
              class="border w-40 img-placeholder"
              data-action="change"
              data-toggle="tooltip"
              title=""
              data-original-title="Change avatar"
            >
              <img class="img-preview" v-if="photo" :src="photo" />
              <template v-if="$v.avatar.$error">
                <span
                  class="form-text text-danger img-val-msg"
                  v-if="!$v.avatar.validateLogo"
                >
                  logo cannot be larger than 2MB
                </span>
              </template>
              <input
                class="d-none"
                type="file"
                name="avatar"
                ref="avatar"
                id="avatar"
                accept="image/jpeg, image/jpg, image/png"
                v-on:input="handleFileUpload()"
                @change="onFileChange($event)"
              />
            </label>
            <span class="form-text text-muted"
              >Allowed file types: png, jpg, jpeg.</span
            >
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >First Name *</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              type="text"
              :class="{ 'is-invalid': $v.firstname.$error }"
              v-model.trim="$v.firstname.$model"
            />
            <template v-if="$v.firstname.$error">
              <small class="text-danger" v-if="!$v.firstname.required">
                firstname is required.
              </small>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Last Name *</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              type="text"
              :class="{ 'is-invalid': $v.lastname.$error }"
              v-model.trim="$v.lastname.$model"
            />
            <template v-if="$v.lastname.$error">
              <small class="text-danger" v-if="!$v.lastname.required">
                lastname is required.
              </small>
            </template>
          </div>
        </div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">Contact Info</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Contact Phone</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <input
                ref="phone"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Phone"
                v-bind:value="currentUser.phone"
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Email Address</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Email"
                v-bind:value="currentUser.email"
              />
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import UserService from "@/core/services/user.service";
import { UPDATE_AUTH } from "@/core/services/store/auth.module";

const validateAvatar = value => {
  if (!value) {
    return true;
  }
  let file = value;
  const buffer = Buffer.from(file.substring(file.indexOf(",") + 1));
  var size = buffer.length / 1e6;
  return size < 2;
};
export default {
  name: "personal-information",
  data() {
    return {
      avatar: null,
      displayAvatar: null,
      firstname: null,
      lastname: null
    };
  },
  validations: {
    avatar: { validateAvatar },
    firstname: { required },
    lastname: { required }
  },
  mounted() {
    this.displayAvatar = this.currentUser.avatar;
    this.firstname = this.currentUser.firstname;
    this.lastname = this.currentUser.lastname;
  },
  methods: {
    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          firstname: this.firstname,
          lastname: this.lastname,
          role_id: this.currentUser.role_id,
          phone: this.currentUser.phone,
          avatar: this.avatar
        };
        const submitButton = this.$refs["kt_save_changes"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        UserService.update(payload, this.currentUser.id)
          .then(response => {
            this.$store.dispatch(UPDATE_AUTH, response.data);
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            this.makeToast("success", "User Updated", response.data.message);
          })
          .catch(error => {
            if (error.status == 422) {
              let errors = Object.values(error.data.errors);
              errors = errors.flat();
              this.makeToast("danger", "Validation Error", errors[0]);
            } else if (error.status == 400) {
              this.makeToast("danger", error.statusText, error.data.message);
            } else {
              this.makeToast(
                "danger",
                "Oops",
                "An unexpected exception has occured. Please give it a minute and refresh page"
              );
            }
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            console.log(error);
          });
      }
    },
    cancel() {},
    onFileChange(e) {
      const file = e.target.files[0];
      this.displayAvatar = URL.createObjectURL(file);
      this.avatar = URL.createObjectURL(file);
    },
    handleFileUpload() {
      const file = this.$refs.avatar.files[0];
      this.getBase64(file).then(data => {
        this.avatar = data;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  },
  computed: {
    ...mapGetters(["currentUser", "layoutConfig"]),
    photo() {
      return this.displayAvatar == null
        ? this.layoutConfig("avatar")
        : this.displayAvatar;
    }
  }
};
</script>
