<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          User Action Parties
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Action party information</span
        >
      </div>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <div
            class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-8"
            v-for="item in actionParties"
            :key="item.id"
          >
            <template v-if="isInParty(item)">
              <div class="card p-6 mb-2 action-party shadow border">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-row align-items-center">
                    <div class="icon">
                      {{ item.name.charAt(0) }}
                    </div>
                    <div class="ml-2">
                      <h6 class="mb-0">{{ item.name }}</h6>
                      <span
                        ><time-ago
                          class="text-sm"
                          :refresh="60"
                          :datetime="item.created_at"
                          locale="en"
                          :long="true"
                          tooltip
                        ></time-ago
                      ></span>
                    </div>
                  </div>
                  <div class="badge">
                    <span>{{ item.user_count }} members</span>
                  </div>
                </div>
                <div class="mt-5">
                  <p>{{ item.description }}</p>
                  <div class="row">
                    <div class="col-9">
                      <div class="stacked-avatars">
                        <template v-if="item.users.length > 0">
                          <span v-for="(e, i) in 5" :key="i" class="avatar">
                            <img
                              class="shadow border"
                              v-b-tooltip.hover.top="
                                item.users[i].firstname +
                                  ' ' +
                                  item.users[i].lastname
                              "
                              v-if="item.users[i]"
                              :src="item.users[i].avatar"
                            />
                          </span>
                        </template>
                        <template v-if="item.users.length == 0">
                          <span class="shadow border span-avatar">.</span>
                        </template>
                      </div>
                    </div>
                    <div class="col-3 text-right"></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { GET_ACTION_PARTIES } from "@/core/services/store/actionparty.module";
import { TimeAgo } from "vue2-timeago";
import { mapGetters } from "vuex";

export default {
  name: "Action-Parties",
  components: {
    TimeAgo
  },
  data() {
    return {
      parties: []
    };
  },
  mounted() {
    this.$store.dispatch(GET_ACTION_PARTIES);
    this.parties = this.currentUser.action_parties;
  },
  methods: {
    isInParty(item) {
      const user_parties = this.parties.map(a => a.id);
      return user_parties.includes(item.id);
    }
  },
  computed: {
    ...mapGetters(["currentUser", "layoutConfig", "hasPermission"]),
    actionParties() {
      return this.$store.state.actionParty.actionParties;
    },
    placeholder() {
      return this.layoutConfig("avatar");
    }
  }
};
</script>
